import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ContactsView from "../views/ContactsView.vue";
import ProductView from "../views/ProductView.vue";
import BuyView from "../views/BuyView.vue";
import GalleryView from "../views/GalleryView.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/contacts",
    name: "contacts",
    component: ContactsView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/product",
    name: "product",
    component: ProductView,
  },
  {
    path: "/buy",
    name: "buy",
    component: BuyView,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryView,
  },
  {
    path: "/menu/groupexercises",
    name: "GroupExercises",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/menu/GroupExercises.vue"
      ),
  },
  {
    path: "/menu/BarMenuNew",
    name: "BarMenuNew",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/menu/BarMenuNew.vue"),
  },
  {
    path: "/menu/barmenu",
    name: "BarMenu",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/menu/BarMenu.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
export default router;
